import { ErrorResponse, ListResponse, Transaction } from 'types';

import { url } from 'statics';
import { api } from 'utils';

const queyTransactions = ({ params }: { params: any }) => {
  return api.get<ListResponse<Transaction>, ErrorResponse, any>(
    url.transactions,
    {
      params,
    }
  );
};


const querySearch = async (search: string) => {
  return api.get<ListResponse<Transaction>, ErrorResponse, any>(
    `${url.users}?search=${search}`
  );
};



export { queyTransactions, querySearch };
