const dashboard = "/dashboard";
const clients = "/clients";
const transactions = "/transactions";
const proposals = "/proposals";
const profile = "/profile";
const wallets = "/wallets";
const auth = "/auth";
const bankAccounts = "/bank-accounts";
const sessions = "/sessions";
const whitelist = "/whitelist";
const assets = "/assets";

// TODO: fix routes
const ROUTES = {
  auth: { url: auth, href: (): string => auth },
  login: { url: `${auth}/login` },
  clients: { url: `${dashboard}${clients}` },
  client: {
    url: `${dashboard}${clients}/:userId`,
    href: (userId: string): string => `${dashboard}${clients}/${userId}`,
  },
  clientWallets: {
    url: `${dashboard}${clients}/:userId${wallets}`,
    href: (userId: string): string =>
      `${dashboard}${clients}/${userId}${wallets}`,
  },
  walletWhitelist: {
    url: `${dashboard}${clients}/:userId${wallets}/:walletId${whitelist}`,
    href: ({
      userId,
      walletId,
    }: {
      userId: string;
      walletId: string;
    }): string =>
      `${dashboard}${clients}/${userId}${wallets}/${walletId}${whitelist}`,
  },
  clientTransactions: {
    url: `${dashboard}${clients}/:userId/${transactions}`,
    href: (userId: string): string =>
      `${dashboard}${clients}/${userId}${transactions}`,
  },
  clientBankAccounts: {
    url: `${dashboard}${clients}/:userId/${bankAccounts}`,
    href: (userId: string): string =>
      `${dashboard}${clients}/${userId}${bankAccounts}`,
  },
  clientSession: {
    url: `${dashboard}${clients}/:userId/${sessions}`,
    href: (userId: string): string =>
      `${dashboard}${clients}/${userId}${sessions}`,
  },
  transactions: {
    url: `${dashboard}${transactions}`,
  },
  transaction: {
    url: `${dashboard}${transactions}/:transactionId`,
    href: (transactionId: string): string =>
      `${dashboard}${transactions}/${transactionId}`,
  },
  proposals: {
    url: `${dashboard}${proposals}`,
  },
  assets: {    url: `${dashboard}${assets}` },
  proposal: {
    url: `${dashboard}${proposals}/:proposalId`,
    href: (proposalId: string): string =>
      `${dashboard}${proposals}/${proposalId}`,
  },
  // --------------------------------------------------------------
  profile: { url: `${dashboard}${profile}` },
  dashboard: { url: dashboard },
};

export default ROUTES;
