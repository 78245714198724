import { Card, Descriptions, Flex, Spin, Tag, Typography } from "antd";
import dayjs from "dayjs";
import {
  ApproveDeposit,
  ApproveWithdraw,
  ApproveWithdrawCrypto,
  RejectDeposit,
  RejectWithdrawCrypto,
} from "pages/UserTransactions/components";
import { Transaction } from "types";

type TotalInfoProps = {
  transactionLoading: boolean;
  refetchTransaction: () => void;
  transaction: Transaction | undefined;
};

const TotalInfo: React.FC<TotalInfoProps> = ({
  transactionLoading,
  transaction,
  refetchTransaction,
}) => {
  const type = {
    DEPOSIT: <Tag color="green">Deposit</Tag>,
    WITHDRAW: <Tag color="red">Withdraw</Tag>,
    TRANSFER: <Tag color="purple">Transfer</Tag>,
    SWAP: <Tag color="geekblue">Swap</Tag>,
    "": <Tag color="geekblue">-</Tag>,
  };

  const category = {
    DEPOSIT_BANK_TRANSFER: <Tag color="default">Bank transfer</Tag>,
    WITHDRAW_BANK_TRANSFER: <Tag color="default">Bank transfer</Tag>,
    WITHDRAW_CRYPTO: <Tag color="default">Crypto</Tag>,
    DEPOSIT_CRYPTO: <Tag color="default">Crypto</Tag>,
    WITHDRAW_LD: <Tag color="default">LD</Tag>,
    DEPOSIT_LD: <Tag color="default">LD</Tag>,
    WITHDRAW_BROKER: <Tag color="default">Broker</Tag>,
    DEPOSIT_BROKER: <Tag color="default">Broker</Tag>,
    DEPOSIT_PAYMENT:  <Tag color="default">Payment</Tag>,
    SWAP: <Tag color="default">Swap</Tag>,
    TRANSFER_USER: <Tag color="default">Transfer</Tag>,
    "": <Tag color="default">-</Tag>,
  };
  const destinationSymbol = {
    DEPOSIT_BANK_TRANSFER:
      transaction?.destination_wallet?.currency?.symbol || "",
    DEPOSIT_CRYPTO: transaction?.destination_wallet?.currency?.symbol || "",
    DEPOSIT_LD: transaction?.destination_wallet?.currency?.symbol || "",
    DEPOSIT_BROKER: transaction?.destination_wallet?.currency?.symbol || "",
    DEPOSIT_PAYMENT: transaction?.destination_wallet?.currency?.symbol || "",
    WITHDRAW_BANK_TRANSFER: transaction?.source_wallet?.currency?.symbol || "",
    WITHDRAW_CRYPTO: transaction?.source_wallet?.currency?.symbol || "",
    WITHDRAW_LD: transaction?.source_wallet?.currency?.symbol || "",
    WITHDRAW_BROKER: transaction?.source_wallet?.currency?.symbol || "",
    SWAP: transaction?.destination_wallet?.currency?.symbol || "",
    TRANSFER_USER: transaction?.destination_wallet?.currency?.symbol || "",
    "": "",
  };
  const sourceSymbol = {
    DEPOSIT_BANK_TRANSFER:
      transaction?.destination_wallet?.currency?.symbol || "",
    DEPOSIT_CRYPTO: transaction?.destination_wallet?.currency?.symbol || "",
    DEPOSIT_LD: transaction?.source_wallet?.currency?.symbol || "",
    DEPOSIT_BROKER: transaction?.destination_wallet?.currency?.symbol || "",
    WITHDRAW_BANK_TRANSFER: transaction?.source_wallet?.currency?.symbol || "",
    WITHDRAW_CRYPTO: transaction?.source_wallet?.currency?.symbol || "",
    WITHDRAW_LD: transaction?.destination_wallet?.currency?.symbol || "",
    WITHDRAW_BROKER: transaction?.source_wallet?.currency?.symbol || "",
    DEPOSIT_PAYMENT: transaction?.source_wallet?.currency?.symbol || "",
    SWAP: transaction?.source_wallet?.currency?.symbol || "",
    TRANSFER_USER: transaction?.source_wallet?.currency?.symbol || "",
    "": "",
  };

  const status = {
    INIT: <Tag color="default">Init</Tag>,
    AUTHORIZED: <Tag color="blue">Authorized</Tag>,
    UNAUTHORIZED: <Tag color="red">Unauthorized</Tag>,
    CLEARED: <Tag color="blue">Cleared</Tag>,
    NOT_CLEARED: <Tag color="red">ot cleared</Tag>,
    APPROVED: <Tag color="blue">Approved</Tag>,
    DENIED: <Tag color="red">Denied</Tag>,
    SETTLING: <Tag color="blue">Settling</Tag>,
    FAILED: <Tag color="red">Failed</Tag>,
    SETTLED: <Tag color="green">Settled</Tag>,
    CANCELED: <Tag color="red">Canceled</Tag>,
    "": <Tag color="default">-</Tag>,
  };

  const { Paragraph, Text } = Typography;

  const swapRate =
    transaction?.transaction_method.type === "SWAP"
      ? [
          {
            key: "swap-rate",
            label: "Rate",
            children: <Text>{transaction?.form.swap.rate}</Text>,
          },
        ]
      : [];

  const descriptionsItems = [
    {
      key: "0",
      label: "Transaction title",
      children: <Text>{transaction?.transaction_method.title}</Text>,
    },
    {
      key: "1",
      label: "Transaction ID",
      children: (
        <Paragraph copyable={{ tooltips: false }}>{transaction?.id}</Paragraph>
      ),
    },
    {
      key: "2",
      label: "Type",
      children: (
        <Text>
          {type[transaction?.transaction_method.type || ""]}
          {category[transaction?.transaction_method.category || ""]}
        </Text>
      ),
    },
    ...swapRate,
    {
      key: "3",
      label: "Amount",
      children: (
        <Text>
          {transaction?.amount ? transaction?.amount.toFixed(5) : "-"}
          <Text
            style={{
              fontSize: "10px",
              paddingLeft: 2,
              color: "green",
            }}
          >
            {sourceSymbol[transaction?.transaction_method.category || ""]}
          </Text>
        </Text>
      ),
    },
    {
      key: "4",
      label: "Destination amount",
      children: (
        <Text>
          {transaction?.destination_amount
            ? transaction?.destination_amount.toFixed(5)
            : "-"}
          <Text
            style={{
              fontSize: "10px",
              paddingLeft: 2,
              color: "green",
            }}
          >
            {destinationSymbol[transaction?.transaction_method.category || ""]}
          </Text>
        </Text>
      ),
    },
    {
      key: "5",
      label: "Status",
      children: <Text>{status[transaction?.status || ""]}</Text>,
    },
    {
      key: "6",
      label: "Fee",
      children: (
        <Text>
          {transaction?.fee.fee_amount || 0}
          <Text
            style={{
              fontSize: "10px",
              paddingLeft: 2,
              color: "green",
            }}
          >
            {sourceSymbol[transaction?.transaction_method.category || ""]}
          </Text>
        </Text>
      ),
    },
    {
      key: "7",
      label: "Created at",
      children: (
        <Text>
          {dayjs(transaction?.created_at).format("YYYY-MM-DD HH:mm:ss")}
        </Text>
      ),
    },
    {
      key: "8",
      label: "Updated at",
      children: (
        <Text>
          {dayjs(transaction?.updated_at).format("YYYY-MM-DD HH:mm:ss")}
        </Text>
      ),
    },
    {
      key: "9",
      label: "Action",
      children: (
        <>
          {transaction?.transaction_method.category ===
            "DEPOSIT_BANK_TRANSFER" &&
            transaction?.status === "CLEARED" && (
              <>
                <ApproveDeposit
                  transactionId={transaction?.id}
                  onApprove={() => refetchTransaction()}
                />
                <RejectDeposit
                  transactionId={transaction?.id}
                  onReject={() => refetchTransaction()}
                />
              </>
            )}
          {transaction?.transaction_method.category ===
            "WITHDRAW_BANK_TRANSFER" &&
            transaction?.status === "APPROVED" && (
              <ApproveWithdraw
                transactionId={transaction?.id}
                onApprove={() => refetchTransaction()}
              />
            )}
          {transaction?.transaction_method.category === "WITHDRAW_CRYPTO" &&
            transaction?.status === "CLEARED" && (
              <>
                <ApproveWithdrawCrypto
                  transactionId={transaction?.id}
                  onApprove={() => refetchTransaction()}
                />
                <RejectWithdrawCrypto
                  transactionId={transaction?.id}
                  onReject={() => refetchTransaction()}
                />
              </>
            )}
        </>
      ),
    },
  ];

  return (
    <Card>
      {transactionLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <Descriptions bordered layout="vertical" items={descriptionsItems} />
      )}
    </Card>
  );
};

export default TotalInfo;
