const auth = "auth";
const users = "users";
const wallets = "wallets";
const sessions = "sessions";
const bankAccounts = "bank-accounts";
const deposits = "deposits";
const withdraws = "withdraws";
const bankTransfer = "bank-transfer";
const crypto = "crypto";
const cryptoWithdrawAccounts = "crypto-withdrawal-accounts";
const whitelist = "whitelist";
const currencies = "currencies";
const ldContract = "ld-contracts";
const transactions = "transactions";
const proposals = "ld-proposals";
const manualTransaction = "manual-transaction";
const contacts = "contacts";
const reports = "reports";

const url = {
  login: `${auth}/initiate-login`,
  verificationLogin: `${auth}/login`,
  logout: `${auth}/logout`,
  profile: "me",
  users: users,
  setRole: `${users}/set-role`,
  changeUsername: (id: string) => `${users}/${id}/update-username`,
  upgradeToLD: `${users}/upgrade-to-ld`,
  upgradeToLvl1: `${users}/upgrade-to-level-1`,
  setPrimaryContact: (id: string, contactId: string) => `${users}/${id}/contacts/${contactId}/primary`,
  deleteContact: (id: string, contactId: string) => `${users}/${id}/contacts/${contactId}`,
  wallets: wallets,
  sessions: sessions,
  bankAccounts: bankAccounts,
  transactions: transactions,
  proposals: proposals,
  roles: "roles",
  permissions: "permissions",
  countries: "countries",
  contacts: contacts,
  createContact: (id: string) => `${users}/${id}/${contacts}`,
  currencies: currencies,
  blockUser: (id: string) => `${users}/${id}/suspension`,
  kycBlockUser: (id: string) => `${users}/${id}/kyc-block`,
  balance: (id: string) => `${users}/${id}/balance`,
  depositApprove: (id: string) => `${deposits}/${bankTransfer}/${id}/approve`,
  depositReject: (id: string) => `${deposits}/${bankTransfer}/${id}/reject`,
  withdrawApprove: (id: string) => `${withdraws}/${bankTransfer}/${id}/settle`,
  withdrawCryptoApprove: (id: string) => `${withdraws}/${crypto}/${id}/approve`,
  withdrawCryptoReject: (id: string) => `${withdraws}/${crypto}/${id}/reject`,
  whitelist: `${whitelist}/${cryptoWithdrawAccounts}`,
  whitelistApprove: (id: string) =>
    `${whitelist}/${cryptoWithdrawAccounts}/${id}/approve`,
  updateLDContract: (id: string) => `${ldContract}/${id}`,
  downgradeToLvl1: `${users}/down-to-level-1`,
  depositManualTransaction: `${deposits}/${manualTransaction}`,
  withdrawManualTransaction: `${withdraws}/${manualTransaction}`,
  approveDepositManualTransaction: (id: string) =>
    `${deposits}/${manualTransaction}/${id}/approve`,
  approveWithdrawManualTransaction: (id: string) =>
    `${withdraws}/${manualTransaction}/${id}/approve`,
  setPrimary: (id: string) => `${contacts}/${id}/primary`,
  userReports: `${reports}/user-details`,
  assets: `${reports}/assets`,
  expenses: `${reports}/expenses`
};
export default url;
