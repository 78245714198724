import {
  ProForm,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import { Card } from "antd";
import React from "react";
import { generateFormProps } from "utils";
import { querySearch } from "../services";
import { DebounceSelect } from "components";

type SearchType = {
  identifier?: string;
  country?: string;
  role?: string;
  username?: string;
};

type SearchFromType = {
  identifier?: string;
  country?: string;
  role?: string;
  username?: string;
};

const Search: React.FC<{
  setSearch: (data: SearchType) => Promise<URLSearchParams>;
  search: SearchType;
  roles: { label: string; value: string }[];
  countries: { label: string; value: string }[];
}> = ({ search, setSearch, countries, roles }) => {
  const formRef = React.useRef<any>();

  const rules = {
    role: [{ required: false }],
    country: [{ required: false }],
    username: [{ required: false }],
    identifier: [{ required: false }],
  };
  const formProps = generateFormProps(
    rules,
    {},
    {
      role: "Role",
      country: "Country",
      username: "Username",
      identifier: "Identifier",
    }
  );

  return (
    <Card style={{ marginBottom: 10 }}>
      <ProForm<SearchFromType>
        formRef={formRef}
        onFinish={async (values) => {
          setSearch(values);
        }}
        initialValues={{ ...search }!}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          flexWrap: "wrap",
        }}
        submitter={{
          searchConfig: { submitText: "Query", resetText: "Reset" },
          onReset: async () => {
            await setSearch({}).then(() => formRef.current.resetFields());
          },
        }}
      >
        <ProFormSelect
          style={{ width: "305px" }}
          {...formProps.role}
          options={roles}
        />
        <ProFormSelect
          style={{ width: "305px" }}
          {...formProps.country}
          options={countries}
        />
        <DebounceSelect
          {...formProps.username}
          fieldProps={{
            size: "middle",
            autoFocus: true,
            autoComplete: "dont-use-autocomplete",
            optionFilterProp: "label",
            style: { width: "305px" },
          }}
          fetchOptions={querySearch}
        />
        <ProFormText {...formProps.identifier} />
      </ProForm>
    </Card>
  );
};

export default Search;
