import React from "react";
import { queyTransactions } from "./services";
import ProTable from "components/ProTable";
import columns from "./columns";
import { Transaction, User } from "types";
import { Button, Flex, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { notification, ProTableRequest, reloadTable } from "utils";
import { ActionType } from "@ant-design/pro-components";
import {
  ApproveDeposit,
  ApproveWithdraw,
  ApproveWithdrawCrypto,
  RejectDeposit,
  RejectWithdrawCrypto,
} from "pages/UserTransactions/components";
import { routes } from "statics";
import exportPDF from "utils/exportPDF";
import { parseAsJson, useQueryState } from "nuqs";
import { z } from "zod";
import Search from "./components/Search";

const Transactions: React.FC = () => {
  const actionRef = React.useRef<ActionType>();
  const [list, setList] = React.useState<any[]>([]);

  const schema = z.object({
    transaction_method: z
      .object({
        type: z.string().optional(),
        category: z.string().optional(),
      })
      .optional(),
    status: z.string().optional(),
    currency: z.string().optional(),
    username: z.string().optional(),
    from_date: z.string().optional(),
    to_date: z.string().optional(),
  });
  const [search, setSearch] = useQueryState(
    "search",
    parseAsJson(schema.parse)
  );

  const transactionsRequest = ProTableRequest<User>(
    ({ params }: { params: any }) =>
      queyTransactions({
        params: {
          ...search,
          pageSize: params.pageSize,
          current: params.current,
          sort: params.sort,
        },
      }),
    setList
  )();

  React.useEffect(() => reloadTable(actionRef), [search]);

  const exportHandler = () => {
    exportPDF({
      data: list.map((item: Transaction) => ({
        type: item.transaction_method.type,
        category: item.transaction_method.category,
        amount: item.amount,
        destination_amount: item.destination_amount,
        destination_wallet_email: item.destination_wallet?.user?.email || "-",
        source_wallet_email: item.source_wallet?.user?.email || "-",
        destination_wallet_name:
          (item.destination_wallet?.user?.profile?.first_name || "-") +
          " " +
          (item.destination_wallet?.user?.profile?.last_name || "-"),
        source_wallet_name:
          (item.source_wallet?.user?.profile?.first_name || "-") +
          " " +
          (item.destination_wallet?.user?.profile?.last_name || "-"),
        fee: item.fee.fee_amount,
        status: item.status,
        created_at: item.created_at,
      })),
      fileName: `transactions-${new Date().getTime()}`,
    });
    notification.success({
      message: "Export was successful",
    });
  };

  const actions = (_: any, item: Transaction) => [
    item.transaction_method.category === "DEPOSIT_BANK_TRANSFER" &&
      item.status === "CLEARED" && (
        <>
          <ApproveDeposit
            transactionId={item.id}
            onApprove={() => reloadTable(actionRef)}
          />
          <RejectDeposit
            transactionId={item?.id}
            onReject={() => reloadTable(actionRef)}
          />
        </>
      ),
    item.transaction_method.category === "WITHDRAW_BANK_TRANSFER" &&
      item.status === "APPROVED" && (
        <ApproveWithdraw
          transactionId={item.id}
          onApprove={() => reloadTable(actionRef)}
        />
      ),
    item.transaction_method.category === "WITHDRAW_CRYPTO" &&
      item.status === "CLEARED" && (
        <>
          <ApproveWithdrawCrypto
            transactionId={item.id}
            onApprove={() => reloadTable(actionRef)}
          />
          <RejectWithdrawCrypto
            transactionId={item?.id}
            onReject={() => reloadTable(actionRef)}
          />
        </>
      ),
    <Typography.Link href={routes.transaction.href(item.id)} target="_blank">
      Detail
    </Typography.Link>,
  ];

  return (
    <>
      <Search search={search!} setSearch={setSearch} />
      <ProTable<User>
        actionRef={actionRef}
        headerTitle={
          <Flex
            style={{ width: "100%" }}
            align="center"
            justify="space-between"
          >
            <Title level={5}>Transactions List</Title>
          </Flex>
        }
        toolBarRender={() => [
          list.length > 0 && (
            <Button onClick={() => exportHandler()}>Export excel</Button>
          ),
        ]}
        columns={columns({ actions, currencyList: {} })}
        request={transactionsRequest}
        options={false}
        search={false}
      />
    </>
  );
};

export default Transactions;
