import React from "react";
import { queyUsers } from "./services";
import ProTable from "components/ProTable";
import columns from "./columns";
import { User } from "types";
import { routes } from "statics";
import { Button, Flex, Tag, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { notification, ProTableRequest, reloadTable } from "utils";
import { useCountries, useRoles, useUserReports } from "hooks";
import exportPDF from "utils/exportPDF";
import Search from "./components/Search";
import { parseAsJson, useQueryState } from "nuqs";
import { z } from "zod";
import { ActionType } from "@ant-design/pro-components";

const Users: React.FC = () => {
  const actionRef = React.useRef<ActionType>();
  const schema = z.object({
    identifier: z.string().optional(),
    country: z.string().optional(),
    role: z.string().optional(),
    username: z.string().optional(),
  });
  const [search, setSearch] = useQueryState(
    "search",
    parseAsJson(schema.parse)
  );

  const [filters, setFilters] = React.useState<Record<string, string>>({});
  const [list, setList] = React.useState<any[]>([]);

  const usersRequest = ProTableRequest<User>(({ params }: { params: any }) => {
    setFilters(params);
    return queyUsers({
      params: {
        ...search,
        pageSize: params.pageSize,
        current: params.current,
        sort: params.sort,
      },
    });
  }, setList)();

  const { data: roles } = useRoles();
  const { data: countries } = useCountries({ enabled: true });
  const { mutateAsync: getReport, isLoading: getReportLoading } =
    useUserReports();

  React.useEffect(() => reloadTable(actionRef), [search]);

  const actions = (_: any, item: User) => [
    <Typography.Link href={routes.client.href(item.id)} target="_blank">
      Detail
    </Typography.Link>,
  ];

  const exportHandler = () => {
    exportPDF({
      data: list.map((item: User) => ({
        username: item.username,
        role: item.role.title,
        identifier_number: item.identifier_number,
        email: item.email,
        full_name: item.profile.first_name + item.profile.last_name,
        created_at: item.created_at,
        is_suspend: item.is_suspended,
      })),
      fileName: `transactions-${new Date().getTime()}`,
    });
    notification.success({
      message: "Export was successful",
    });
  };

  return (
    <>
      <Search
        search={search!}
        setSearch={setSearch}
        roles={
          roles?.list.map((item: any) => ({
            label: item.title,
            value: item.id,
          })) || []
        }
        countries={
          countries?.list.map((item: any) => ({
            label: item.title,
            value: item.id,
          })) || []
        }
      />
      <ProTable<User>
        actionRef={actionRef}
        headerTitle={
          <Flex
            style={{ width: "100%" }}
            align="center"
            justify="space-between"
          >
            <Title level={5}>Users List</Title>
          </Flex>
        }
        columns={columns(actions)}
        toolBarRender={() => [
          <Button
            type="primary"
            onClick={() =>
              getReport(filters).then((data) =>
                exportPDF({
                  data,
                  fileName: `user-detail-reports-${new Date().getTime()}`,
                })
              )
            }
            loading={getReportLoading}
          >
            Report
          </Button>,
          list.length > 0 && (
            <Button onClick={() => exportHandler()}>Export excel</Button>
          ),
        ]}
        request={usersRequest}
        options={false}
        search={false}
      />
    </>
  );
};

export default Users;
