import React from "react";

import { ProFormSelect } from "@ant-design/pro-form";
import { Spin } from "antd";
import debounce from "lodash/debounce";

import type { ProFormSelectProps } from "@ant-design/pro-form";
import type { ProFormFieldItemProps } from "@ant-design/pro-form/es/typing";
import type { BaseOptionType, SelectProps } from "antd/es/select";

const DebounceSelect: React.FC<
  ProFormFieldItemProps<SelectProps<any>> & {
    fetchOptions: any;
    initialOptions?: ProFormSelectProps["options"];
    debounceTimeout?: number;
    getActiveValue?: (_value: BaseOptionType | BaseOptionType[]) => void;
  }
> = ({
  fetchOptions,
  initialOptions = [],
  debounceTimeout = 300,
  fieldProps,
  getActiveValue = (_value) => {},
  ...props
}) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ProFormSelectProps["options"]>(
    []
  );
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      if (value !== "") {
        setFetching(true);
        fetchOptions(value).then(
          (newOptions: { list: { id: string; username: string }[] }) => {
            if (fetchId !== fetchRef.current) {
              return;
            }
            setOptions(
              newOptions.list.map((item) => ({
                label: item.username,
                value: item.username,
              }))
            );
            setFetching(false);
          }
        );
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  React.useEffect(() => {
    if (initialOptions.length > 0) {
      setOptions(initialOptions);
    }
  }, [initialOptions]);

  return (
    <ProFormSelect
      showSearch
      fieldProps={{
        filterOption: false,
        onSearch: debounceFetcher,
        notFoundContent: fetching ? <Spin size="small" /> : null,
        onChange: (_value, option) => getActiveValue(option),
        ...fieldProps,
      }}
      {...props}
      options={options}
    />
  );
};

export default DebounceSelect;
