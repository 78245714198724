import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { url } from 'statics';

import { api } from 'utils';
import type { ErrorResponse, UserReport } from 'types';


const useUserReports = (): UseMutationResult<
  UserReport[],
  ErrorResponse,
  Record<string, string>
> => useMutation((data) => {
  const qs = new URLSearchParams(Object.entries(data).filter(([key]) => !['sort', 'filter', 'pageSize', 'current'].includes(key)))

  return api.get(`${url.userReports}?${qs}`)
});

export default useUserReports;
